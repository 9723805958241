import { GridApi } from '@plarin/inputs';
import { components } from '../campaigns/apispec';
import { TYDMetric as TMetric } from '../metrics';

export type TMetricsForGroupingYD = Record<TYdObjType, TMetric[]>;

export type TManageYDData = Record<string, any>;

export type Dictionary = Record<string, string>;

export type TMetricGroupYD = components['schemas']['apicore__api__yd__metrics__FlatMetircsGroupItemResp'];

export type TYDMetric = components['schemas']['YdFieldItem'];

export type TYdFieldItem = components['schemas']['YdFieldItem']['obj_types'];

export type TYdObjType = components['schemas']['YdObjType'];

export type TYdAdGroupsParamsReq = components['schemas']['YdAdsParamsReq'];

export type TRelativePeriodDate = components['schemas']['RelativePeriodDate'];

export type TStatusYD = components['schemas']['YdStatusReq'];

export enum EntitiesYD {
  account = 'account',
  campaign = 'campaign',
  ad_group = 'ad_group',
  ad = 'ad',
}

export const entitiesYD = {
  ACCOUNT: EntitiesYD.account,
  CAMPAIGN: EntitiesYD.campaign,
  GROUP: EntitiesYD.ad_group,
  AD: EntitiesYD.ad,
};

export enum EntitiesNameYD {
  accounts = 'Accounts',
  campaigns = 'Campaigns',
  ad_groups = 'Groups',
  ads = 'Ads',
}

export const entitiesNameYD = {
  ACCOUNTS: EntitiesNameYD.accounts,
  CAMPAIGNS: EntitiesNameYD.campaigns,
  GROUPS: EntitiesNameYD.ad_groups,
  ADS: EntitiesNameYD.ads,
};

export type ActionContentPropsYd = { gridApi: GridApi };
